html,
body {
  min-height: 100%;
}

html {
  background: linear-gradient(180deg, #3F2B96 0%, #A8C0FF 100%);
}

.App {
  max-width: 1100px;
  margin: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 15rem;
}

@media screen and (max-width: 992px) {
  body {
    padding: 15px;
  }
}

div.field>label {
  display: block;
}

.p-panel.p-component {
  margin-bottom: 20px;
}

.p-panel.p-component>.p-toolbar {
  border-radius: 6px 6px 0 0;
}